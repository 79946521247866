.submit-button {
  }

  .cancel-button {
  }

  .MuiDialog-root {
    overflow-y: scroll !important;
  }

  .MuiDialog-container {
    height: unset !important;
  }

  .emptyPhotoInput {
    border-radius: 4px;
    background: rgb(242, 244, 245);
    width: 100%;
    height: 100%;
    padding: 0px 20px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }

  .photosInput {
    width: 1000px;
  }

  .imagesInputErrorMsg {
    color: red;
  }

  .removeButton {
    z-index: 1;
    position: absolute;
    right: 10px;
    top: 10px;
  }

.error {
  color: red !important;
  border: solid !important;
}