.news-footer {
    display: inline-block
}

.news-footer-content {
    display: inline-block;
    width: 100%;
    margin-right: 1em;
}

.author {
    text-align: right;
    color: rgba(0, 0, 0, 0.54);
    font-size: 16px;
    font-family: 'Work Sans', sans-serif;
}

.add-news-comment-text-field {
    width: 100%;
}

.add-news-comment-button {
    margin-top: 1em;
}

.news-body {
    position: absolute;
    top: 0px;
}

#news .MuiCardHeader-title {
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-weight: 400 !important;
    font-size: 2.125rem !important;
    line-height: 1.235 !important;
    letter-spacing: 0.00735em !important;
    margin-bottom: 0.35em !important;
}

#news .MuiPaper-rounded {
    border-radius: unset !important;
}

#news .MuiPaper-elevation1 {
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.05) !important;
}