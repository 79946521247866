.MuiDialog-root {
  overflow-y: scroll !important;
}

.MuiDialog-container {
  height: unset !important;
}

.table-bordered {
  border: 1px solid;
  border-collapse: collapse;
}

.table-bordered td {
  border: 1px solid;
}

.news-header {
  width: 90%;
}

.news-path {
  width: 90%;
}