
.breadcrumbs {
    width: 100% !important;
    background-color: rgb(240, 240, 240);
    max-width: none !important;
    padding-left: 10px !important;
    padding-top: 10px !important;
  }

.container {
    padding-top: 40px;
    padding-bottom: 4px;
}

.go-to-order {
  height: 15vh;
  min-height: 170px;
  padding: 40px;
}

.go-to-order-content {
  height: 50%;
}

.go-to-order-button {
  height: 50%;
}

.item {
  padding: 30px;
  position: relative;
  margin-bottom: 10px;
}

.item-image {
  width: 200px;
  height: 200px;
  overflow:hidden;
}

.item-content {
  height: 100%;
}
.item-content-header {
  height: 10%;
}

.item-content-footer {
  text-align: right;
  height: 40%;
}

.item-content-body {
  height: 50%;
  word-break: break-all;
}

.item-cost {
  margin-top: 10px;
  padding-top: 10px;
}


