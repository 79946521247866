.swiper-button-prev {
    color: #ff3366 !important; 
}

.swiper-button-next {
    color: #ff3366 !important; 
}

.swiper-pagination-bullet {
    background-color: #ff3366 !important; 
}