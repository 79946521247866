/* At last you have to copy these css styles from here ,Create a CSS file and paste it and import the file */

/* Also you can customize these css styles*/

/* In my case, I use these CSS styles in my App.css file*/
.itemDetailsPage  .swiper, .itemDetailsPageMobile .swiper {
  width: unset !important;
padding: 0px;
  margin: 0px;
}

.itemDetailsPage .swiper-slide {
  background-position: center;
  background-size: cover;
  height: 480px;
  overflow: hidden;
}

.itemDetailsPageMobile .swiper-slide {
  background-position: center;
  background-size: cover;
  height: 260px;
  overflow: hidden;
}

.itemDetailsPage .swiper-slide img, .itemDetailsPageMobile .swiper-slide img {
  width: unset !important;
  display: block;
}

.itemDetailsPage .imgFullHeight, .itemDetailsPageMobile .imgFullHeight {
  transform: translateX(-50%);
    position: relative;
    left: 50%;
    height: 100%;
}

.itemDetailsPage .imgFullWidth, .itemDetailsPageMobile .imgFullWidth {
  transform: translateY(-50%);
    position: relative;
    top: 50%;
    width: 100%;
}


.breadcrumbs {
  width: 100% !important;
  background-color: rgb(240, 240, 240);
  max-width: none !important;
  padding-left: 10px !important;
  padding-top: 10px !important;
}

.add-to-cart {
  float: right;
}

.itemDetailsPageMobile .MuiCardContent-root {
  padding: 0px !important;
  padding-bottom: 5px !important;
  scroll-margin-top: 5px;
}