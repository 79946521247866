#statisticsPageItem .MuiCardHeader-title {
    font-family: "Roboto","Helvetica","Arial",sans-serif !important;
    font-weight: 400 !important;
    font-size: 2.125rem !important;
    line-height: 1.235 !important;
    letter-spacing: 0.00735em !important;
    margin-bottom: 0.35em !important;
}

#statisticsPageItem .MuiPaper-rounded {
    border-radius: unset !important;
}

#statisticsPageItem .MuiPaper-elevation1 {
    box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.05) !important;
}

#statisticsPageItem {
    padding-left: 48px;
    padding-right: 48px;
    padding-top: 24px;
    padding-bottom: 24px;
}

#statisticsPageItem #content {
    word-break: break-word
}