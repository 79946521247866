.breadcrumbs {
    width: 100% !important;
    background-color: rgb(240, 240, 240);
    max-width: none !important;
    padding-left: 10px !important;
    padding-top: 10px !important;
  }

.container {
    padding-top: 40px;
    padding-bottom: 4px;
}

.buttons {
    padding-left: 15%;
    padding-right: 15%;
}

.buttons-left {
    float: left;
    margin: 10px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-bottom: 7px !important;
    padding-top: 7px !important;
}

.buttons-right {
    float: right;
    margin: 10px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-bottom: 7px !important;
    padding-top: 7px !important;
}

.buttons-right-submit {
    float: right;
    margin: 10px !important;
    padding-right: 20px !important;
    padding-left: 20px !important;
    padding-bottom: 7px !important;
    padding-top: 7px !important;
}

.content {
    padding-left: 15%;
    padding-right: 15%;
}

.billing-address-input {
    width: 100%;
}