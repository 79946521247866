.sc {
    padding: 0px !important;
}

.scw {
    display: flex;
    align-items: center;
    padding-left: 14px;
    padding-right: 14px;
    height: 100%;
}