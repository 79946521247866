/* At last you have to copy these css styles from here ,Create a CSS file and paste it and import the file */

/* Also you can customize these css styles*/

/* In my case, I use these CSS styles in my App.css file*/
.swiper {
  width: 80%;
  padding-top: 5px;
  padding-bottom: 50px;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 480px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}