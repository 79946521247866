.add-new-grid-list-tile {
  height: 400px !important;
  width: 400px !important;
  background-color: rgba(224, 224, 224, 0.692);
}


.add-new-grid-list-tile:hover {
  background-color: rgb(224, 224, 224);
}

.add-new-grid-list-tile.error {
  outline: auto !important;
  outline-color: red !important;
}

.click-to-load-file-text {
  position: absolute;
  top: 40%;
  left: 23%;
  color: white;
  z-index: 1;
}

.click {
  font-weight: 800;
}

.modal-header-input {
  color: white;
  width: 95%;
  line-height: 30px;
  margin-bottom: 1%;
  background-color: #0000008c;
  border: unset;
  outline: none;
}

.modal-header-input.error {
  outline: auto !important;
  outline-color: red !important;
}

.modal-header-input::placeholder {
  color: rgba(255, 255, 255, 0.774);
}

.breadcrumbs {
  width: 100% !important;
  background-color: rgb(240, 240, 240);
  max-width: none !important;
  padding-left: 10px !important;
  padding-top: 10px !important;
}

.tile-editor-input {
  width: 80%;
}